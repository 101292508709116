
<template>
  <div id="index">
    <div class="bg">
      <div class="top">
        <dv-decoration-8 style="width: 20%; height: 50px" />
        <dv-decoration-10 style="width: 60%; height: 32px" />
        <!-- <dv-decoration-10 style="width:90%;height:5px;" /> -->
        <dv-decoration-8 :reverse="true" style="width: 20%; height: 50px" />
      </div>
      <div class="title">
        <dv-decoration-6
          style="
            margin-right: 20px;
            width: 100px;
            height: 30px;
            padding-top: 11px;
          "
        />
        <span class="title-text">现场评审数据中心</span>
        <dv-decoration-6
          style="
            width: 100px;
            height: 30px;
            margin-left: 20px;
            padding-top: 11px;
          "
        />
      </div>
      <div class="search" :class="{ toHeight: isShow }" ref="navUl">
        项目分组：
        <div
          v-for="item in classify.slice(0, classify.length)"
          :key="item.stagegroupName"
          class="compute"
          :class="{ active: active == item.stagegroupName }"
          @click="selected(item.stagegroupName)"
        >
          {{ item.stagegroupName }}
        </div>
      </div>
      <div class="more" v-if=" w>sw">
        <div class="morebt">
          <i
            v-if="!isShow"
            class="el-icon-arrow-down"
            style="font-size: xx-large"
            @click="morebt"
          ></i>
          <i
            v-else
            class="el-icon-arrow-up"
            style="font-size: xx-large"
            @click="morebt"
          ></i>
        </div>
      </div>
      <div class="introduce" ref="introduce">
        <div class="border"></div>
        <div style="padding-left: 10px;font-size: 35px;line-height: 1.75;">
          {{stageName}} — ({{active}})
        </div>
      </div>

      <div class="chart">
        <div class="right">
          <div class="table-header">
            <div style="width:10vw;text-align: center">排名</div>
            <div style="width:35vw;text-align: center;">项目名称</div>
            <div style="width:15vw;text-align: center;">所属院校</div>
            <div style="width:15vw;text-align: center;">评审状态</div>
            <div style="flex-grow: 1;text-align: center">最终得分</div>
          </div>
          <div class="dv-scroll-board" style="width: 100%; height: 56.7vh; overflow: hidden;" ref="scrollBox">
            <div class="rows" ref="scrollBoxRows">
              <!--<template v-for="(project, index) in this.projectList">
                <div class="row-item" style="height: 6.3vh; line-height: 6.3vh; background-color: rgb(1, 35, 114);">
                  <div align="center" class="ceil" style="width: 10vw;">
                    <div style="font-size:20px; font-family: Times New Roman, Times, serif">No.{{ index+1 }} <span class="iconfont icon-jiangbei" style="color:gold"></span></div>
                  </div>
                  <div class="ceil" style="width: 50vw;">
                    <div style="font-size:18px;margin: 0;padding: 0;text-align: center;text-overflow: ellipsis;overflow: hidden;">{{project.contprojName}}</div>
                  </div>
                  
                  <template v-if= "currentProjectId===project.contprojId">
                    <div class="ceil" style="flex-grow: 1;">
                      <div style="color:red;font-size: 20px;  text-align: center; font-weight: bold;">在评</div>
                    </div>
                    <div class="ceil" style="flex-grow: 1;">
                      <div style="font-size:22px;color:red ;text-align: center;">{{project.score}}</div>
                    </div>
                  </template>
                  <template v-else-if= "project.stageprojScoreStatus>0 && project.score!=null">
                    <div class="ceil" style="flex-grow: 1;">
                      <div style="color:yellow;font-size: 20px;  text-align: center; font-weight: bold;">已评</div>
                    </div>
                    <div class="ceil" style="flex-grow: 1;">
                      <div style="font-size:22px;color:yellow ;text-align: center;">{{project.score}}</div>
                    </div>
                  </template>
                  <template v-else>
                    <div class="ceil" style="flex-grow: 1;">
                      <div style="font-size: 20px;  text-align: center; font-weight: bold;">未评</div>
                    </div>
                    <div class="ceil" style="flex-grow: 1;">
                      <div style="font-size:22px;text-align: center;">--</div>
                    </div>
                  </template>
                </div>
              </template>-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import '../assets/iconfont.css'
var timer1; //这是实时获取数据的定时器
var timer2; //路演答辩倒计时定时器
export default {
  data() {
    return {
      sw:0,         //search的宽度
      w:0,         //子元素的宽度和
      introduceH:'',  //inrroduxe的高度
      style1:'',
      style2:'',
      min: 0,      //倒计时分钟
      sec: 0,       //倒计时秒针
      time: "",    //用来显示倒计时的时间
      timer: "",   //定时器
      status:0,    //判断是否倒计时  1为开启

      config: {},  //项目列表
      config1: {},   // 评委列表
      classify: [],  //分组列表
      active: "",    //分组按钮是否被选中
      isShow: false,  //是否展示过多的分组
      isAnimate:false,  // 是否启用翻滚动画


      //---请求所需数据
      stageliveId:'', //赛事现场id
      stageId:'',     //环节id
      groupId:'',  //分组id
      contestId:'',  //赛事id
      stageName:'',  // 大赛名
      currentProjectname:'',  //当前在评项目的名字 
      stagelivePartNumber:0,  // 当前再评项目的阶段 默认开始为0：转场等待

      bjcurrentdata:'',      //用于比较的data，实时获取到的数据，与之前的数据没有变化的话就不重新渲染
      bjjudgerdata:'',
      bjprojectdata:'',
      currentProjectId:'',
      projectList:[],
      projectIndex:0,
      projectTime:'',
      fullMarks: 0, //满分

    };
  },
  mounted() {
    // //获取组别信息，和大赛信息
    this.getGroupData();
  },
  methods: {
    //倒计时
    timedown() {
      if (this.min <= 0 && this.sec <= 0) {
        return
      }
      if (this.sec > 0) {
        this.sec--;
      } else {
        this.min--;
        this.sec = 59;
      }
      this.time =
        (this.min >= 10 ? this.min : "0" + this.min) +
        ":" +
        (this.sec >= 10 ? this.sec : "0" + this.sec);
        this.countdown();
    },
    countdown(){
      let dospan = document.getElementById("countdown");
      if(dospan!=null){
        dospan.innerHTML= this.time;
      }
    },
    
    //分组按钮选中事件
    selected(name) {
      this.active = name; //高光
      this.getgroupId(name)
      this.FirstgetGroupProjectData() //获取相应组的项目信息
    },
    //下拉菜单按钮切换
    morebt() {
      this.isShow = !this.isShow;
    },

    //弹出错误提示
    openError(text) {
        this.$message.error(text);
    },
    //切换评审头像
    getImgUrl(stagejudgeStatus){
        if(stagejudgeStatus==0){
          return require('../assets/touping-people.png')
        }
        else{
          return require('../assets/touping-gou.png')
        }
    },
    //----------向后台发请求

    //获取组别信息，和大赛信息
		getGroupData() {
				this.$post("/api/stagelive/getLiveGroupData", {
					stageliveId:this.$route.query.stageliveId,
				}).then((res) => {
          if(!res.result){ //失败  返回错误信息
            this.openError(res.resultMsg)
            clearInterval(timer1)
            return
          }
          else{
            this.classify=res.resultData.groupList
            this.active=res.resultData.groupName
            this.groupId=res.resultData.groupId
            this.stageName=res.resultData.contest.contestName
            this.stageId=res.resultData.stage.stageId
            this.contestId=res.resultData.contest.contestId
            this.fullMarks=res.resultData.fullMarks;
            this.FirstgetGroupProjectData()
          }
					
				}).catch((res) => {
					console.log(res);
				})
			},
      //首次获取对应组别的 项目信息  
		FirstgetGroupProjectData() {
        var that = this;
				this.$post("/api/stagelive/getLiveGroupProjectData", {
					contestId:this.contestId,
          stageId:this.stageId,
          groupId:this.groupId,  
				}).then((res) => {
          if(!res.result){  //失败 返回错误信息
            clearInterval(timer1)
            this.changeprojectList({})
            this.getcurrentProject({})
            this.openError(res.resultMsg)
            return
          }
          else{
            this.currentProjectId = res.resultData.currentProject.currentProjectId;
            this.getcurrentProject(res.resultData.currentProject);
            this.changeprojectList(res.resultData.projectList)
            
            this.bjprojectdata=res.resultData.projectList
            this.bjcurrentdata=res.resultData.currentProject
            clearInterval(timer1);
            timer1 = setInterval(function() {
              that.NextTimeget()
            }, 1000);
            //根据子元素的宽度判断分组按钮有没有换行
            this.w=0
            this.sw=this.$refs.navUl.clientWidth
            this.$refs.navUl.children.forEach(v=>{
              this.w=this.w+v.clientWidth+parseInt(getComputedStyle(v).getPropertyValue('margin-right'))
            })
            //判断class：introduce 的高度给表格进行不同的高度 
            this.introduceH=this.$refs.introduce.clientHeight
            if(this.introduceH>65){
              this.style1="width: 100%; height: 60%" 
              this.style2="width: 100%; height: 80%"
            }else{
              this.style2="width: 100%; height: 87%"
              this.style1="width: 100%; height: 64%"
            }

            //答辩或者路演的时候
            if(res.resultData.currentProject.stagelivePartNumber==1 || res.resultData.currentProject.stagelivePartNumber==2 || res.resultData.currentProject.stagelivePartNumber==3){
              //时间复位
              if(res.resultData.currentProject.status==0 && res.resultData.currentProject.startTime==""){
                  that.getcurrentProject(res.resultData.currentProject);
                  if(timer2){
                    clearInterval(timer2);
                    timer2=null;
                  }
              }else if(res.resultData.currentProject.status==1 && (this.min>0 || this.sec>0)){ //时间开始
                if(!timer2){
                  timer2 = setInterval(function() {
                    that.timedown();
                  }, 1000);
                }
              }else{ //时间暂停
                var minutes = parseInt(res.resultData.currentProject.leftSec/60)<10 ? "0"+parseInt(res.resultData.currentProject.leftSec/60):parseInt(res.resultData.currentProject.leftSec/60);
                var second = (res.resultData.currentProject.leftSec%60)<10 ? "0"+(res.resultData.currentProject.leftSec%60):(res.resultData.currentProject.leftSec%60);
                this.time = minutes+":"+second;
                if(timer2){
                  clearInterval(timer2);
                  timer2=null;
                }
              }
            }
          }	
				}).catch((res) => {
					console.log(res);
				})
			},
      //实时获取数据
    NextTimeget(){
      var that = this;
				this.$post("/api/stagelive/getLiveGroupProjectData", {
					contestId:this.contestId,
          stageId:this.stageId,
          groupId:this.groupId,
				}).then((res) => {
          if(!res.result){  //失败 返回错误信息
            this.openError(res.resultMsg)
          }
          else{
            //只有一个赛事现场情况下，根据当前项目切换分组
            if(res.resultData.liveSign==1 && res.resultData.currentProject.groupId!=null && res.resultData.currentProject.groupId!=this.groupId){
              for(let i=0;i<this.classify.length;i++){
                if(res.resultData.currentProject.groupId==this.classify[i].stagegroupId){ this.selected(this.classify[i].stagegroupName); }
              }
            }

            //如果当前项目发生变化，刷新项目列表
            if(this.currentProjectId!=res.resultData.currentProject.currentProjectId){
              that.getcurrentProject(res.resultData.currentProject);
              this.changeprojectList(res.resultData.projectList)
            }

            let stspan = document.getElementById("stage-name");
            if(stspan!=null){
              if(res.resultData.currentProject.stagelivePartNumber==1){
                stspan.innerHTML="，路演："
              }else if(res.resultData.currentProject.stagelivePartNumber==2){
                stspan.innerHTML="，答辩："
              }else if(res.resultData.currentProject.stagelivePartNumber==3){
                stspan.innerHTML="，评审："
              }else{
                stspan.innerHTML=""
                let dospan = document.getElementById("countdown");
                dospan.innerHTML=""
              }
            }

            //答辩、路演或评审的时候
            if(res.resultData.currentProject.stagelivePartNumber==1 || res.resultData.currentProject.stagelivePartNumber==2 || res.resultData.currentProject.stagelivePartNumber==3){
              //时间复位
              if(res.resultData.currentProject.status==0 && res.resultData.currentProject.startTime==""){
                  that.getcurrentProject(res.resultData.currentProject);
                  this.countdown();
                  if(timer2){
                    clearInterval(timer2);
                    timer2=null;
                  }
              }else if(res.resultData.currentProject.status==1 && (this.min>0 || this.sec>0)){ //时间开始
                if(!timer2){
                  that.timedown();
                  timer2 = setInterval(function() {
                    that.timedown()
                  }, 1000);
                }
              }else{ //时间暂停
                this.min=parseInt(res.resultData.currentProject.leftSec/60)
                this.sec=res.resultData.currentProject.leftSec%60
                var minutes = parseInt(res.resultData.currentProject.leftSec/60)<10 ? "0"+parseInt(res.resultData.currentProject.leftSec/60):parseInt(res.resultData.currentProject.leftSec/60);
                var second = (res.resultData.currentProject.leftSec%60)<10 ? "0"+(res.resultData.currentProject.leftSec%60):(res.resultData.currentProject.leftSec%60);
                this.time = minutes+":"+second;
                this.countdown();
                if(timer2){
                  clearInterval(timer2);
                  timer2=null;
                }
              }
            }else{
              that.getcurrentProject(res.resultData.currentProject);
              if(timer2){
                clearInterval(timer2);
                timer2=null;
              }
            }

            //判断项目列表的数据有没有发送变化
            for(let j=0;j<that.bjprojectdata.length;j++){
              if(that.bjprojectdata[j].score!=res.resultData.projectList[j].score){
                that.changeprojectList(res.resultData.projectList)
                this.bjprojectdata=res.resultData.projectList  //更新比较数据
                break
              }
            }
          }	
				}).catch((res) => {
					console.log(res);
				})
    },
    //把获取到的 项目数据 变成我想要的格式
    changeprojectList(data){
      var that = this;
      const wrapper = this.$refs.scrollBoxRows;
      let boo = false;
      while (wrapper.firstChild) {
        wrapper.removeChild(wrapper.firstChild);
      }
      
      for(let i=0;i<data.length;i++){
        if(i<10){
          this.generateProjectHtml(data[i], i+1);
        }else{
          break;
        }
      }
      if(data.length>9){
        that.projectIndex = 10;
        if(this.projectTime) clearInterval(this.projectTime);
        this.projectTime = setInterval(() => {
          let scrollBoxRows = this.$refs.scrollBoxRows;
          if(boo){
            scrollBoxRows.removeChild(scrollBoxRows.children[0]);
            that.generateProjectHtml(data[that.projectIndex], that.projectIndex+1);
            if(data.length>that.projectIndex+1){
              that.projectIndex++;
            }else{
              that.projectIndex=0;  
            }
          }

          let children = scrollBoxRows.children[0];
          children.style.height = '0';
          boo = true;

        }, 2000);
      }else{
        if(this.projectTime) clearInterval(this.projectTime);
      }
    },
    generateProjectHtml(project, inx){
      let scrollBoxRows = this.$refs.scrollBoxRows;
      const newChild = document.createElement('div');
      newChild.style="height: 6.3vh; line-height: 6.3vh; background-color: rgb(1, 35, 114);"
      newChild.classList.add("row-item");
      var str = '';
      var color = '';
      var score = '';
      var jbei = '';
      let jbeicolor=['gold','silver','darkgoldenrod'];
      var stagelivePartName = '';
      if(this.stagelivePartNumber==1){
        stagelivePartName = '<span id="stage-name">，路演：</span><span id="countdown">'+this.time+'</span>';
      }else if(this.stagelivePartNumber==2){
        stagelivePartName = '<span id="stage-name">，答辩：</span><span id="countdown">'+this.time+'</span>';
      }else if(this.stagelivePartNumber==3){
        stagelivePartName = '<span id="stage-name">，评审：</span><span id="countdown">'+this.time+'</span>';
      }else{
        stagelivePartName = '<span id="stage-name"></span><span id="countdown"></span>';
      }
      if(this.currentProjectId==project.contprojId){
        str = '在评'+stagelivePartName; 
        color = 'red';
      }else if(project.stageprojScoreStatus>0 && project.score!=null ){ 
        str = '已评'; 
        color = 'yellow';
      }else{
        str = '未评'; 
        color = '';
      }
      var scoreSch = 0;
      if(project.stageprojScoreStatus==0 || project.score==null){
        score='--';
        jbei = '';
        scoreSch = 0;
      }else{
        score=project.score
        if(inx<=3){
          jbei = '<span class="iconfont icon-jiangbei" style="color:'+jbeicolor[inx-1]+'"></span>';
        }else{
          jbei = '';
        }
        color = 'yellow';
        scoreSch = score/this.fullMarks*100;
      } 

      newChild.innerHTML = '<div align="center" class="ceil" style="width: 10vw;">\
                              <div style="font-size:20px; font-family: Times New Roman, Times, serif">No.'+(inx)+jbei+'</div>\
                            </div>\
                            <div class="ceil" style="width: 35vw;">\
                              <div style="font-size:18px;margin: 0;padding: 0;text-align: center;text-overflow: ellipsis;overflow: hidden;">'+project.contprojName+'</div>\
                            </div>\
                            <div class="ceil" style="width: 15vw;">\
                              <div style="font-size:18px;margin: 0;padding: 0;text-align: center;text-overflow: ellipsis;overflow: hidden;">'+project.contprojOrgName+'</div>\
                            </div>\
                            <div class="ceil" style="width: 15vw;">\
                              <div style="color:'+color+';font-size: 20px;  text-align: center; font-weight: bold;">'+str+'</div>\
                            </div>\
                            <div class="ceil" style="flex-grow: 1;">\
                              <!--<div class="jdbar" style="position: relative; width:40%; top:3.7vh; height: 5px; background-color: #bbb;border-radius: 15px;">\
                                <div style="width: '+scoreSch+'%;height: 100%; background-color: gold;border-radius: 15px"></div>\
                              </div>-->\
                              <div style="font-size:22px;color:'+color+' ;text-align: center;">'+score+'</div>\
                            </div>';
      scrollBoxRows.appendChild(newChild);
    },
    //当前在评项目数据
    getcurrentProject(data){
        this.currentProjectname=data.name
        this.currentProjectId=data.currentProjectId
        this.stagelivePartNumber=data.stagelivePartNumber
        this.min=parseInt(data.leftSec/60)
        this.sec=data.leftSec%60
        this.time =
        (this.min >= 10 ? this.min : "0" + this.min) +
        ":" +
        (this.sec >= 10 ? this.sec : "0" + this.sec);
    },
    //获取项目分组id
    getgroupId(name){
      for(let i=0;i<this.classify.length;i++){
        if(name==this.classify[i].stagegroupName){ this.groupId=this.classify[i].stagegroupId}
      }
      if(this.isShow){this.reSort(this.groupId)}
    },
    //项目分组重排列
    reSort(id){
      var b=[]
      for(let i=0;i<this.classify.length;i++){
         if(id!=this.classify[i].stagegroupId){ 
          b.push(this.classify[i])
         }
         else{
          break
         }
      }
      for(let j=0;j<b.length;j++){
        this.classify.shift()
        this.classify.push(b[j])
      }
       this.isShow=false
    }
   
  },
  watch:{
    // 被侦听的变量stagelivePartNumber
    stagelivePartNumber(){
      setTimeout(() => {
        this.isAnimate=false
      }, 1200)               
    },
    currentProjectname(){
      setTimeout(() => {
        this.isAnimate=false
      }, 1200)               
    }
  },
  beforeDestroy() {
      clearInterval(timer1);
      clearInterval(timer2);
      clearInterval(this.projectTime);
    }

};
</script>


<style scoped>
#index {
  color: #e5ebf8;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform-origin: left top;
  overflow: hidden;
}
.bg {
  width: 100%;
  height: 100%;
  padding: 0;
  background-color: #00154f;
  background-image: url(../assets/bg\ \(1\).jpg);
  background-size: cover;
  background-position: center center;
  position: relative;
}

.bg div,.bg span{
  font-size: 22px
}
.top {
  width: 100%;
  display: flex;
}
.title {
  width: 50%;
  position: absolute;
  top: 3.5%;
  left: 25%;
  display: flex;
  justify-content: center;
  font-size: xx-large;
}
.title .title-text {
  color: rgb(255, 255, 255);
  text-shadow: rgb(0, 216, 255) 0px 0px 25px;
  font-size: 46px;
  font-family: "Adobe 楷体 Std R";
}
.search {
  display: flex;
  margin: 5% 4% 0px;
  align-items: center;
  align-items: baseline;
  flex-wrap: wrap;
  height: 5%;
  overflow: hidden;
}
.search div{
  margin-right: 2%;
}
.toHeight{
    overflow: unset;
     height:auto; 
}
.compute {
  background-color: #072d84;
  cursor: pointer;
  padding: 0 10px;
  height: 30px;
  text-align: center;
  border-radius: 5px;
  line-height: 30px;
  font-size: smaller;
  border: 1px solid floralwhite;
  margin-bottom: 1.5%;
}
.introduce {
  position: relative;
  display: flex;
  width: 92%;
  /* height: 65px; */
  background-color: #01205e;
  color: #01e1f2;
  margin: 1.1% 4% 2%;
  flex-wrap: wrap;
  /* overflow: hidden; */
  text-overflow: ellipsis
}
.border {
  position: absolute;
  height: 100%;
  background-color: #5cbee8;
  width: 5px;
  border-radius: 5px 0 0 5px;
  margin-right: 3px;
}
.chart {
  display: flex;
  /* margin-left: 26px; */
  height: 100%;
  margin-left: 4%;
  margin-right: 4%;
}
.right {
  width: 100%;
  height: 90%;
}
.right .table-header {
  display: flex;
  height: 5%;
  background-color: #072d84;
  border: 1px solid #5cbee8;
  align-items: center;
  /* justify-content: center; */
  border-radius: 5px 5px 0 0;
}

/*四个角的*/

.active {
  background-color: #3a8ee6;
}

.more {
  display: flex;
  flex-direction: column;
  margin: 0 68px;
}
.morebt {
  margin: 2px;
  text-align: center;
}
.morebt i {
  font-size: xx-large;
  width: 50px;
  border-radius: 15px;
}

.morebt i:hover {
  background-color: mediumblue;
  cursor: pointer;
}
</style>
